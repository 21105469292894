<template>
    <div v-if="(merchantcode || this.$root.productID) && !success" class="af_container">

      <div class="af_header af_b_margin af_b_padding">
        <h2 class="af_page_heading af_b_margin" :style="[$root.textColor,$root.fontH1]">Ask a Question</h2>
        <form class="af_ask_question">
          <div class="af_b_margin">
            <label for="InputQuestion" :style="[$root.textColor,$root.sizeLabel]">Your Question <span style="color:red">*</span></label>
            <textarea v-model="question" style="height: auto" rows="6" cols="50" class="af_form-control" placeholder="Type your querstion here..."></textarea>
            <small style="color:red" v-if="getValidationError('customerQuestion')">{{getValidationError('customerQuestion')}}</small>
          </div>
          <div class="af_b_margin">
            <label for="NickName" :style="[$root.textColor,$root.sizeLabel]">Nickname <span style="color:red">*</span></label>
            <input type="text" v-model="name" autofocus class="af_form-control" id="NickName" placeholder="The name will display with your question">
            <small style="color:red" v-if="getValidationError('customerName')" v-text="getValidationError('customerName')"></small>
          </div>
          <div class="af_b_margin">
            <input type="checkbox" id="emailCheckbox" class="form-check-input" @click="emailCheckbox=!emailCheckbox" :checked="emailCheckbox">&nbsp;
            <label class="af_check_label af_font_400" for="emailCheckbox"><small :style="[$root.textColor,$root.sizeBody]"> Send me an email when my question is answered</small></label>
          </div>
          <div v-if="emailCheckbox">
            <label for="InputEmail" :style="[$root.textColor,$root.sizeLabel]">Email Address</label>
            <input type="text" class="af_form-control" v-model="email" id="InputEmail" placeholder="example@example.com">
            <small style="color:red" v-if="getValidationError('customerEmail')" v-text="getValidationError('customerEmail')"></small>
            <!-- <small style="color:red" v-if="emailRequired">Email address is required</small> -->
          </div>

        </form>
        <div class="af_flex af_t_margin">
          <button @click="submitQuestion" :style="[$root.primaryColorButton,$root.sizeLabel]" v-text="submitLabel" :disabled="loading" class="af_btn "></button>
          <router-link rel="nofollow" v-if="!loading" style="margin-left: 15px" :style="[$root.textColor,$root.sizeLink]" :to="{ name: 'home', params: { merchantcode: merchantcode, sku: sku, domain: $root.domain }}"
                    class="af_align_self_center af_text_muted af_no_line">Cancel</router-link>
        </div>
        <p style="color:red" v-if="getValidationError('gc')"><small v-text="getValidationError('gc')"></small></p>
        <p v-if="error && !errors" style="color:red"><small>Something went wrong, Please try again.</small></p>
      </div>
    </div>

    <div v-else-if="success" class="af_container" id="af_hide_function">
      <div class="af_header af_b_margin af_b_padding" >
        <h1 v-if="deletedSuccess && isDeleted" style="line-height: 1.4" class="af_page_heading af_b_margin af_alert af_alert_success">Your question has been successfully deleted.</h1>
        <h1 v-else-if="deletedSuccess && isDeleted==false" style="line-height: 1.4" class="af_page_heading af_b_margin af_alert af_alert_warning">Your question cannot be deleted at the moment</h1>
        <h1 v-else style="line-height: 1.4" class="af_page_heading af_b_margin af_alert af_alert_success">Your question was successfully posted</h1>

      <section class="af_b_margin_big" v-if="!isDeleted">
        <div class="af_row af_question af_flex">
          <div class="af_w_fixed1 af_left">
            <h4 :style="[$root.textColor,$root.sizeBody]">Q<span class="af_text_hide">uestion</span>:</h4>
          </div>
          <div class="af_qcontent af_user_question af_w_fixed2 af_left af_ie10_flex_width_fix">
            <h4 :style="[$root.textColor,$root.sizeBody]" class="af_margin_0" style="">{{successData.customer_question}}</h4>
            <small class="af_text_muted af_full_width af_b_margin af_t_margin_sml" :style="[$root.textColor,$root.sizeSmall]" >Asked on {{successData.created_at}}</small>
            <a v-if="!confirmDelete" @click="confirmDelete=true" class="af_btn af_delete_btn hide-first" rel="nofollow" href="javascript:void(0)">Delete Question</a></div>
        </div>
        <div v-if="confirmDelete" :style="$root.bgColor" class="af_ask_btn p af_delete_confirm af_t_margin af_full_width" style="border: 1px solid #e0e0e0; padding: 15px; background: #fafafa">
          <p class="af_align_self_center" :style="$root.textColor">Are you sure you want to delete your question?</p>
          <div class="af_flex af_align_self_center"><a v-text="deleteLabel" :disabled="loading" @click="deleteQuestion" class="af_btn af_delete_btn" href="javascript:void(0)"></a></div>
        </div>
        <small class="af_text_muted af_full_width" style="margin-top:10px;" :style="[$root.textColor,$root.sizeSmall]"><b>Note:</b> Once someone answers your question, it will be posted on the site and you’ll be notified by email.</small>
      </section>

      <small :style="[$root.textColor,$root.sizeBody]">See all questions about this product
        <router-link rel="nofollow" style="font-size: 13px;" :to="{ name: 'home', params: { merchantcode: merchantcode, sku: sku, domain: $root.domain }}"
                      class="af_align_self_center af_no_line" :style="$root.primaryColor">Click here</router-link>
      </small>
    </div>
    <p v-if="error" style="color:red"><small>Something went wrong, Please try again.</small></p>
    </div>

    <div v-else-if="!merchantcode">
        Direct access not allowed
    </div>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6Ldhy-QUAAAAAK-LO3-EPAsK2WNOV-Vra-sB5e_k' })

export default {
  props: ['propQuestion', 'merchantcode', 'sku', 'domain'],
  data () {
    return {
      name: null,
      email: null,
      loading: false,
      recaptchaToken: null,
      submitLabel: 'Submit',
      deleteLabel: 'Delete Question',
      success: false,
      deletedSuccess: false,
      error: false,
      errors: {},
      successData: null,
      deleteData: null,
      confirmDelete: false,
      question: this.propQuestion,
      emailCheckbox: true
    }
  },
  mounted () {
    this.recaptcha()
    this.$Console.log(this.$root.productID)
  },
  methods: {
    async recaptcha () {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      const recaptcha = this.$recaptchaInstance
      // Hide reCAPTCHA badge:
      recaptcha.hideBadge()

      // Execute reCAPTCHA with action "login".
      this.recaptchaToken = await this.$recaptcha('login')
    },
    submitQuestion: function (event) {
      this.loading = true
      this.submitLabel = 'Submitting...'
      event.preventDefault()

      let emailRequired = 0
      if (this.emailCheckbox && !this.email) {
        emailRequired = 1
      } else {
        emailRequired = 0
      }

      let url = this.$siteURL + 'savequestion?gc=' + this.recaptchaToken + '&api_token=' + this.$root.token
      if (this.$root.productID) {
        url = this.$siteURL + 'savequestion?gc=' + this.recaptchaToken + '&shop=' + this.$root.domain
      }

      this.$axios
        .post(url, {
          customerQuestion: this.question,
          customerName: this.name,
          customerEmail: this.email,
          product_sku: this.sku,
          product_id: this.$root.productID,
          domain: this.$root.domain,
          emailRequired: emailRequired,
          merchant_code: this.merchantcode
        })
        .then(response => {
          this.success = true
          this.loading = false
          this.error = false
          this.submitLabel = 'Submit'
          this.successData = response.data
          // this.$Console.log(response)
        })
        .catch(error => {
          this.error = true
          this.loading = false
          this.submitLabel = 'Submit'
          this.errors = error.response.data
          this.recaptcha() // Reloading the token
          this.$Console.log(error)
        })
    },
    deleteQuestion: function (event) {
      this.loading = true
      this.deleteLabel = 'Deleting...'
      let url = this.$siteURL + 'deletequestion?api_token=' + this.$root.token
      if (this.$root.productID) {
        url = this.$siteURL + 'deletequestion?shop=' + this.$root.domain
      }

      this.$axios
        .post(url, {
          questionID: this.successData.community_question_id
        })
        .then(response => {
          this.deletedSuccess = true
          this.loading = false
          this.deleteLabel = 'Delete'
          this.deleteData = response.data
          // this.$Console.log(response)
        })
        .catch(error => {
          this.error = true
          this.loading = false
          this.deleteLabel = 'Delete'
          this.$Console.log(error)
        })
    },
    getValidationError: function (field) {
      if (this.errors.errors) {
        if (this.errors.errors[field]) {
          return this.errors.errors[field][0]
        }
      }
      return false
    }
  },
  computed: {
    isDeleted: function () {
      if (this.deleteData) {
        if (this.deleteData[0] === 'true') {
          return true
        } else {
          return false
        }
      } else {
        return null
      }
    }
  }
}
</script>
